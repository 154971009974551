
import 'hamburgers/dist/hamburgers.css';
import { Manipulation } from 'swiper';
import './css/main.css';


var SmoothScroll = require('smooth-scroll');

var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 600,
    speedAsDuration: true
});


function toggleMenu() {
  var ham = document.getElementById("hamburger");
  ham.classList.toggle("is-active");
  var menu = document.getElementById("menu");
  menu.classList.toggle("translate-x-full");
  var moverme = document.getElementsByClassName("moveme");
  var i;
  for (i=0; i < moverme.length; i++) {
    moverme[i].classList.toggle("move-left");
  };
};

window.toggleMenu = toggleMenu;